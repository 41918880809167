<template>
	  <v-text-field
        hide-details dense outlined readonly
        :label="label"
        :ref="name"
        :value="value"
        :placeholder="label"
        :reverse="reverse[format]"
    ></v-text-field>
</template>
<script>
export default {
    name: 'TextInput',
    props: ['label', 'format', 'name', 'value'],
    data: () => ({
        reverse: { 'default': false, 'number': true, 'date': false, 'decimal': true, 'euro': true, 'proz': true }
    }),
    mounted() {
        console.log('TextInput[' + this.name + ']:', this.format, this.reverse[this.format])
    },
}
</script>